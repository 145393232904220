import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { win } from '@src/app-utils'
import BrightCoveVideoComponent from '@components/brightcove-video/brightcove-video.component'
import { IMG_STATE } from '@utils/faces.util'

class FaceComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  shouldComponentUpdate(nextProps) {
    return this.props.video.state !== nextProps.video.state || this.props.video.title !== nextProps.video.title
  }
  render() {
    const isMobile = win && window.innerWidth < 480
    const { video, index } = this.props
    const faceClasses = {
      [IMG_STATE.NORMAL]: isMobile ? 'face face-big' : 'face',
      [IMG_STATE.HOVER]: 'face active',
      [IMG_STATE.BIG_IMG]: 'face face-big',
      [IMG_STATE.VIDEO]: 'face face-big'
    }
    const img = {
      [IMG_STATE.NORMAL]: isMobile ? video.img.big : video.img.reg,
      [IMG_STATE.HOVER]: video.img.active,
      [IMG_STATE.BIG_IMG]: video.img.big
    }

    let faceProps = {
      onClick: () => this.props.onClick()
    }
    if (!isMobile) {
      faceProps.onMouseEnter = () => this.props.onMouseEnter()
      faceProps.onMouseLeave = () => this.props.onMouseLeave()
    }
    let faceBlock = (
      <div key={index} className={faceClasses[video.state]} {...faceProps}>
        <img src={img[video.state]} alt={video.img.imgAlt} />
      </div>
    )
    if (video.state === IMG_STATE.VIDEO && video.videoId) {
      faceBlock = (
        <div key={index} className="face face-big">
          <BrightCoveVideoComponent
            player={video.player}
            account={video.account}
            videoId={video.videoId}
            tracking={video.tracking}
            autoPlay={true}
            ref={el => (this.currentVideoRef = el)}
          />
        </div>
      )
    }
    return <div>{faceBlock}</div>
  }
}

FaceComponent.propTypes = {
  video: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
}

export default FaceComponent
